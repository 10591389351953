export const UPDATE_PRODUCTS = '@products/udpate-products';
export const UPDATE_PRODUCT = '@products/update-product';
export const UPDATE_PRODUCT_ATTRIBUTE = '@products/update-product-attribute';
export const UPDATE_PRODUCT_ATTRIBUTES = '@products/update-product-attributes';
export const UPDATE_VARIANT = '@products/update-variants';
export const REMOVE_PRODUCT = '@products/remove-product';
export const REMOVE_PRODUCT_ATTRIBUTE = '@products/remove-product-attribute';

export function updateProductVariant(variant) {
    return dispatch => dispatch({
        type: UPDATE_VARIANT,
        payload: {
            variant
        }
    })
}

export function updateProducts(data) {
    return (dispatch) => dispatch({
        type: UPDATE_PRODUCTS,
        payload: {
            data
        },
    });
}

export function updateProductAttributes(attributes) {
    return (dispatch) => dispatch({
        type: UPDATE_PRODUCT_ATTRIBUTES,
        payload: {
            attributes
        }
    })
}

export function updateProductAttribute(attribute) {
    return (dispatch) => dispatch({
        type: UPDATE_PRODUCT_ATTRIBUTE,
        payload: {
            attribute
        }
    })
}

export function updateProduct(product) {
    return (dispatch) => dispatch({
        type: UPDATE_PRODUCT,
        payload: {
            product
        }
    })
}

export function removeProduct(productId) {
    return (dispatch) => dispatch({
        type: REMOVE_PRODUCT,
        payload: {
            productId
        }
    })
}

export function removeProductAttribute(attributeId) {
    return (dispatch) => dispatch({
        type: REMOVE_PRODUCT_ATTRIBUTE,
        payload: {
            attributeId
        }
    })
}