export const UPDATE_ORDERS = '@orders/update-visitors';
export const UPDATE_SHIPPING_ZONES = '@orders/update-shipping-zones';
export const UPDATE_SHIPPING_ZONE = '@orders/update-shipping-zone';
export const REMOVE_SHIPPING_ZONE = '@orders/remove-shipping-zone';
export const UPDATE_VOUCHERS = '@orders/update-vouchers';
export const UPDATE_VOUCHER = '@orders/update-voucher';
export const REMOVE_VOUCHER = '@orders/remove-voucher';
export const UPDATE_ORDER = '@orders/update-visitor';
export const REMOVE_ORDER = '@orders/remove-visitor';

export function updateOrders(data) {
    // dispatch thread update
    return (dispatch) => dispatch({
        type: UPDATE_ORDERS,
        payload: {
            data
        }
    });
}

export function updateVouchers(vouchers) {
    return (dispatch) => dispatch({
        type: UPDATE_VOUCHERS,
        payload: {
            vouchers
        }
    })
}

export function updateVoucher(voucher) {
    return (dispatch) => dispatch({
        type: UPDATE_VOUCHER,
        payload: {
            voucher
        }
    })
}

export function removeVoucher(voucherId) {
    return (dispatch) => dispatch({
        type: REMOVE_VOUCHER,
        payload: {
            voucherId
        }
    })
}

export function updateShippingZones(zones) {
    return (dispatch) => dispatch({
        type: UPDATE_SHIPPING_ZONES,
        payload: {
            zones
        }
    })
}

export function updateShippingZone(zone) {
    return (dispatch) => dispatch({
        type: UPDATE_SHIPPING_ZONE,
        payload: {
            zone
        }
    })
}

export function removeShippingZone(zoneId) {
    return (dispatch) => dispatch({
        type: REMOVE_SHIPPING_ZONE,
        payload: {
            zoneId
        }
    })
}

export function updateOrder(order) {
    return (dispatch) => dispatch({
        type: UPDATE_ORDER,
        payload: {
            order
        }
    })
}

export function removeOrder(orderId) {
    return (dispatch) => dispatch({
        type: REMOVE_ORDER,
        payload: {
            orderId
        }
    })
}